import { extendTheme } from "@chakra-ui/react"

const theme = {
  breakpoints: {
    sm: "36em",
    md: "48em",
    lg: "62em",
    xl: "75em",
    "2xl": "96em",
  },
  fonts: {
    body: "Overpass, Helvetica Neue, sans-serif",
    heading: "Overpass, Helvetica Neue, sans-serif",
    mono: "Overpass, Helvetica Neue, sans-serif",
  },
  styles: {
    global: {
      body: {
        fontFamily: "Overpass, Helvetica Neue, sans-serif",
      },
    },
  },
  colors: {
    tmi: {
      blue: "#00a6a5",
      orange: "#f36e21",
      darkerOrange: "#de6118",
      yellow: "#f5ba33",
      darkerYellow: "#ed9d17",
      gray: "#e4e4e4",
      darkGray: "#4a4a4a",
      darkerGray: "#313131",
      transDarkGray: "rgba(37, 37, 37, 0.67)",
      transGray: "rgba(49, 49, 49, .5)",
      textGray: "#434141",
    },
  },
  components: {
    Heading: {
      sizes: {
        heading: {
          fontSize: { base: "7vw", md: "5vw", lg: "48px" },
          lineHeight: { base: "8vw", md: "5vw", lg: "53px" },
          fontWeight: 700,
        },
        subHeading: {
          fontSize: { base: "24px", lg: "30px" },
          lineHeight: { base: "28px", lg: "36px" },
          fontWeight: 600,
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "0px",
      },
      sizes: {
        md: {
          h: "2.4375rem",
          fontSize: "14px",
          pl: "1.2rem",
          pr: "1.2rem",
        },
      },
    },
  },
}

export default extendTheme(theme)
